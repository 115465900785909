import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { CreateTargetCommand, MetaboliteModuleRow, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MoleculeStructureComponent } from '../../../rdkit/molecule-structure/molecule-structure.component';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { FormsModule, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { AppService } from '../../../services/app.service';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MSGraphService } from '../../../services/msgraph.service';
import { ActivatedRoute } from '@angular/router';
import { StepperModule } from 'primeng/stepper';
import { InputGroupModule } from 'primeng/inputgroup';
import { MetaboliteDeepDiveService } from '../../../services/metabolite-deep-dive.service';
import { ChemdrawWrapperComponent } from '../../../chemdraw-wrapper/chemdraw-wrapper.component';

@Component({
  selector: 'app-create-target',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ResearchComponentsCoreModule,
    BlockUIModule,
    OverlayPanelModule,
    MoleculeStructureComponent,
    CardModule,
    TooltipModule,
    ButtonModule,
    FloatLabelModule,
    FormsModule,
    BadgeModule,
    ChipModule,
    SliderModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    InputGroupModule,
    ChemdrawWrapperComponent
],
  templateUrl: './create-target.component.html',
  styleUrl: './create-target.component.scss'
})
export class CreateTargetComponent implements AfterViewInit {
  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;


  display: boolean;
  // @Output() displayChange = new EventEmitter();
  selectedRow: MetaboliteModuleRow;

  @Output() targetCreated = new EventEmitter<TargetStatus>();

  createTargetFormGrp: FormGroup;
  createTargetCmd: CreateTargetCommand;
  creatingTarget: boolean;
  activeStep: number = 0;
  showChemdraw = false;

  constructor(
    private messageService: MessageService,
    public metabolitesService: MetaboliteModuleService,
    private appService: AppService,
    private graphService: MSGraphService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mmdd: MetaboliteDeepDiveService
  ) {


    this.createTargetFormGrp = this.fb.group({
      selectionType: [null, Validators.required],
      hypothesis: ['', [Validators.required]],
      type: [null, [Validators.required]],
      adduct: [null],
      gcfId: [null]
    });
  }


  ngAfterViewInit(): void {
    this.createTargetCmd = new CreateTargetCommand({
      msNodeId: this.selectedRow.msNodeId,
      batchId: this.selectedRow.batchId,
      strainID: this.selectedRow.strainID,
      predictedStructureSMILES: this.selectedRow.spectrumCompoundSMILES
    });
  }

  onSubmit() {
    if (this.createTargetFormGrp.valid) {
      this.createTarget();
    }
  }

  createTarget() {
    this.creatingTarget = true;

    this.createTargetCmd.selectionType = this.createTargetFormGrp.get("selectionType").value;
    this.createTargetCmd.hypothesis = this.createTargetFormGrp.get("hypothesis").value;
    this.createTargetCmd.adduct = this.createTargetFormGrp.get("adduct").value;
    this.createTargetCmd.gcfId = this.createTargetFormGrp.get("gcfId").value;
    this.createTargetCmd.type = this.createTargetFormGrp.get("type").value;


    this.metabolitesService.createTarget(this.createTargetCmd).subscribe(x => {
      Object.assign(this.selectedRow.status, x);
      this.targetCreated.emit(x);
    }, null, () => {
      this.display = false;
      this.creatingTarget = false;
    });
  }

  updateSmiles() {
    if (this.showChemdraw) {
      this.cdw.chemdraw.getSMILES((smiles: any, error: any) => {
        this.createTargetCmd.predictedStructureSMILES = smiles;
      });
      this.showChemdraw = false;
    }
  }

  show(selectedRow: MetaboliteModuleRow) {
    this.selectedRow = selectedRow;

    this.createTargetCmd = new CreateTargetCommand({
      msNodeId: this.selectedRow.msNodeId,
      batchId: this.selectedRow.batchId,
      strainID: this.selectedRow.strainID,
      predictedStructureSMILES: this.selectedRow.spectrumCompoundSMILES
    });

    this.createTargetFormGrp.reset();

    this.metabolitesService.getCreateTargetInfo(this.selectedRow.msNodeId, undefined).subscribe(x => {
      this.activeStep = 0;
      this.display = true;
    });


  }

}
