import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { MetaboliteModuleRow, RejectTargetCommand, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { forkJoin, Observable } from 'rxjs';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { FormsModule, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { AppService } from '../../../services/app.service';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MSGraphService } from '../../../services/msgraph.service';
import { ActivatedRoute } from '@angular/router';
import { StepperModule } from 'primeng/stepper';
import { InputGroupModule } from 'primeng/inputgroup';
import { MetaboliteDeepDiveService } from '../../../services/metabolite-deep-dive.service';


@Component({
  selector: 'app-reject-target',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ResearchComponentsCoreModule,
    BlockUIModule,
    OverlayPanelModule,
    CardModule,
    TooltipModule,
    ButtonModule,
    FloatLabelModule,
    FormsModule,
    BadgeModule,
    ChipModule,
    SliderModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    InputGroupModule
  ],
  templateUrl: './reject-target.component.html',
  styleUrl: './reject-target.component.scss'
})
export class RejectTargetComponent {

  @Output() targetsRejected = new EventEmitter<boolean>();

  rejectTargetFormGrp: FormGroup;
  selectedRow: MetaboliteModuleRow;
  rejectTargetCmd: RejectTargetCommand;
  display = false;

  constructor(
    private messageService: MessageService,
    public metabolitesService: MetaboliteModuleService,
    private appService: AppService,
    private graphService: MSGraphService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mmdd: MetaboliteDeepDiveService
  ) {

    this.rejectTargetFormGrp = this.fb.group({
      rejectReason: [null, Validators.required]
    });

  }

  show() {
    this.rejectTargetFormGrp.reset();
    this.display = true;
  }

  rejectTargets() {
    let obs: Observable<TargetStatus>[] = [];

    for (let row of this.metabolitesService.selectedData) {
      var cmd = new RejectTargetCommand({
        batchId: this.appService.selectedBatchName(),
        msNodeId: row.msNodeId,
        rejectReason: this.rejectTargetFormGrp.get("rejectReason").value
      });
      obs.push(this.metabolitesService.rejectTarget(cmd, row));
    }

    forkJoin(obs).subscribe(x => {
      //this.metabolitesService.updateTargetButtons();
      this.targetsRejected.emit(true);
    }, null, () => {
      this.display = false;

    });


  }

}
