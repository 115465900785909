<p-dialog header="Header" [modal]="true" [(visible)]="display"
    [style]="{ width: '55rem',  'min-height': '46rem'  }" appendTo="body">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <i class="pi pi-bullseye right-side-icon target"></i>
            <span class="font-bold white-space-nowrap">
                Create Target
            </span>
        </div>
    </ng-template>


    <ng-container *ngIf="selectedRow">
        <div>
            <p-table [value]="[selectedRow]" [tableStyle]="{ 'width': '100%' }" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr>
                        <th>MS Node</th>
                        <th>m/z</th>
                        <th>Spectral Family</th>
                        <th>Strain</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td>
                            {{row.msNodeId}}
                        </td>
                        <td>
                            {{row.mz}}
                        </td>
                        <td>
                            {{row.spectralFamilyId}}
                        </td>
                        <td>
                            {{row.strainID}}
                        </td>
                        <td>

                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>

        <form id="createTargetForm" [formGroup]="createTargetFormGrp" (ngSubmit)="onSubmit()">

            <p-stepper [(activeStep)]="activeStep">
                <p-stepperPanel header="Basic Information">
                    <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">

                        <div style="min-height: 22rem;" class="mt-2">
                            <div class="formgrid grid">
                                <div class="field col-5">
                                    <label for="selectionType">Selection Type</label>
                                    <p-dropdown id="selectionType"
                                        [options]="metabolitesService.createTargetInfo?.selectionCriteria"
                                        placeholder="Selection Criteria " formControlName="selectionType" />

                                    <small *ngIf="createTargetFormGrp.get('selectionType')?.hasError('required')"
                                        class="p-error">Selection Type is required.</small>
                                </div>

                                <div class="field col-2">
                                    <label for="type">Type</label>
                                    <p-dropdown id="type" [options]="metabolitesService.createTargetInfo?.types"
                                        placeholder="Type" formControlName="type" />

                                    <small *ngIf="createTargetFormGrp.get('type')?.hasError('required')"
                                        class="p-error">Type is
                                        required.</small>
                                </div>

                                <div class="field col-2">
                                    <label for="gcfId">GCF Id</label>
                                    <p-dropdown id="gcfId" [options]="metabolitesService.createTargetInfo?.gcfIds"
                                        placeholder="GCF Id" formControlName="gcfId" />
                                </div>

                                <div class="field col-3">
                                    <label for="selectionType">Adduct</label>
                                    <p-dropdown id="adduct" [options]="metabolitesService.createTargetInfo?.aducts"
                                        optionLabel="name" optionValue="name" placeholder="Adduct "
                                        formControlName="adduct" />
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="hypothesis">Hypothesis</label>
                                    <textarea id="hypothesis" rows="5" cols="80" pInputTextarea
                                        formControlName="hypothesis">
                                </textarea>
                                    <small *ngIf="createTargetFormGrp.get('hypothesis')?.hasError('required') "
                                        class="p-error">Hypothesis is required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-end">
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>

                <p-stepperPanel header="Predicted Structure">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                        let-index="index">

                        <div style="min-height: 22rem;" class="mt-2 ml-4">
                            <div class="flex flex-row">
                                <div class="flex">
                                    <div class="field ">
                                        <label for="gcfId">SMILES</label>
                                        <p-inputGroup>
                                            <input id="smiles" type="text" pInputText
                                                [(ngModel)]="this.createTargetCmd.predictedStructureSMILES"
                                                [ngModelOptions]="{standalone: true}">
                                            <p-button type="button" icon="pi pi-times"
                                                (onClick)="this.createTargetCmd.predictedStructureSMILES = undefined"
                                                size="small"></p-button>
                                        </p-inputGroup>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-row">
                                <div class="flex">
                                    <div class="field ">
                                        <label for="gcfId">Structure</label>
                                        <app-molecule-structure [width]="175" [height]="175"
                                            [structure]="this.createTargetCmd.predictedStructureSMILES"
                                            *ngIf="this.createTargetCmd.predictedStructureSMILES"
                                            [svgMode]="false"></app-molecule-structure>
                                    </div>
                                </div>
                                <div class="flex" *ngIf="this.createTargetCmd.predictedStructureSMILES">
                                    <div class="flex flex-column">
                                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                            (onClick)="showChemdraw=true" size="small" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>

                <p-stepperPanel header="Graphs">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                        let-index="index">
                        <div style="min-height: 22rem;" class="mt-2 ml-4">
                            <div>
                                Drag / Paste 4 Graphs Here
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>

                <p-stepperPanel header="Finish">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                        <div style="min-height: 22rem;" class="grid mt-2 ml-4">
                            <div class="col-8">
                                <table>
                                    <tr>
                                        <td class="header">Batch</td>
                                        <td>{{createTargetCmd.batchId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">MS Node ID</td>
                                        <td>{{createTargetCmd.msNodeId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">m/z</td>
                                        <td>{{selectedRow.mz}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Spectral Family</td>
                                        <td>{{selectedRow.spectralFamilyId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Strain ID</td>
                                        <td>{{createTargetCmd.strainID}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">GCF ID</td>
                                        <td>{{createTargetFormGrp.get('gcfId').value}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Selection Type</td>
                                        <td>{{createTargetFormGrp.get('selectionType').value}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Hypothesis</td>
                                        <td>{{createTargetFormGrp.get('hypothesis').value}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Adduct</td>
                                        <td>{{createTargetFormGrp.get('adduct').value}}</td>
                                    </tr>

                                    <tr>
                                        <td class="header">Type</td>
                                        <td>{{createTargetFormGrp.get('type').value}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">SMILES</td>
                                        <td>{{createTargetCmd.predictedStructureSMILES}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header"># Graphs</td>
                                        <td>0/4</td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-4">
                                <div class="field ">
                                    <label for="gcfId">Predicted Structure</label>
                                    <app-molecule-structure [width]="200" [height]="200"
                                        [structure]="this.createTargetCmd.predictedStructureSMILES"
                                        *ngIf="this.createTargetCmd.predictedStructureSMILES"
                                        [svgMode]="false"></app-molecule-structure>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <button pButton label="Finish" type="submit" form="createTargetForm" icon="pi pi-check"
                                iconPos="right" [disabled]="!createTargetFormGrp.valid || creatingTarget"></button>
                        </div>
                    </ng-template>
                </p-stepperPanel>
            </p-stepper>
        </form>
    </ng-container>

    <!-- <ng-template pTemplate="footer">
        <p-button label="Cancel" severity="secondary" (onClick)="showCreateTarget = false" />
        <button pButton label="Save" type="submit" form="createTargetForm"></button> </ng-template> -->

    <div #appendHere>

    </div>

</p-dialog>



<p-dialog header="Header" [modal]="true" appendTo="body" [(visible)]="showChemdraw"
    styleClass="ag-custom-component-popup" [style]="{ width: '55rem', height: '46rem'  }" closable="false">

    <ng-template pTemplate="header">
        <h5>Draw Structure</h5>
    </ng-template>

    <div style="height: 490px;" *ngIf="this.createTargetCmd?.predictedStructureSMILES">
        <app-chemdraw-wrapper #cdw *ngIf="showChemdraw"
            [(smiles)]="this.createTargetCmd.predictedStructureSMILES"></app-chemdraw-wrapper>
    </div>

    <div class="mt-4">

    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="showChemdraw = false" />
        <p-button label="OK" [outlined]="true" severity="secondary" (onClick)="updateSmiles()" />
    </ng-template>
</p-dialog>

