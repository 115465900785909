<p-dialog header="Header" [modal]="true" [(visible)]="display"
    [style]="{ width: '55rem',  'min-height': '23rem'  }" appendTo="body">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <i class="pi pi-ban right-side-icon target"></i>
            <span class="font-bold white-space-nowrap">
                Reject {{metabolitesService.selectedData.length}} Target(s)
            </span>
        </div>
    </ng-template>

    <form id="rejectTargetForm" [formGroup]="rejectTargetFormGrp" (ngSubmit)="rejectTargets()">
        <div class="formgrid grid">
            <div class="field col">
                <label for="hypothesis">Reject Reason</label>
                <textarea id="rejectReason" rows="5" cols="80" pInputTextarea formControlName="rejectReason">
        </textarea>
                <small *ngIf="rejectTargetFormGrp.get('rejectReason')?.hasError('required') " class="p-error">Reject
                    Reason
                    is required.</small>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="display = false" />
        <!-- <p-button label="OK" [outlined]="true" severity="secondary" (onClick)="rejectTargets()" /> -->

        <button pButton label="OK" type="submit" form="rejectTargetForm" icon="pi pi-check" iconPos="right"
            [disabled]="!rejectTargetFormGrp.valid"></button>

    </ng-template>

</p-dialog>
