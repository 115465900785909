<div class="grid">
    <div class="col-4">
        <h6>SpectralFamilyId: {{mmdd.deepDive()?.spectralFamilyId}}</h6>
    </div>
    <div class="col-4">
        <h6>C Score: {{mmdd.deepDive()?.cScore}}</h6>
    </div>
    <div class="col-4">
    </div>
</div>

<ag-grid-angular class="ag-theme-quartz" style="height: 400px;" [rowData]="mmdd.deepDive()?.metaboliteModuleRows"
    [columnDefs]="msNodesColDef" [pagination]="true" [paginationPageSize]="10" [paginationPageSizeSelector]="[10,25,50]"
    [defaultColDef]="defaultColDef" sideBar="filters" [rowSelection]="{ mode: 'multiRow',checkboxes: false,
    headerCheckbox: true, selectAll: 'currentPage',
    enableClickSelection: true}" (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"/>


<p-tabView>
    <p-tabPanel header="Structure">
        <ag-grid-angular class="ag-theme-quartz" style="height: 400px;" [rowData]="mmdd.compounds"
        [columnDefs]="compoundsColDef" [pagination]="true" [paginationPageSize]="10"
        [paginationPageSizeSelector]="[10,25,50]" [defaultColDef]="defaultColDef" sideBar="filters" [autoGroupColumnDef]="{ headerName: 'Match Type'}"/>


    </p-tabPanel>
    <p-tabPanel header="Bioactivity">
        <ag-grid-angular class="ag-theme-quartz" style="height: 400px;" [rowData]="mmdd.bioactivity"
        [columnDefs]="bioactivityColDef" [pagination]="true" [paginationPageSize]="10"
        [paginationPageSizeSelector]="[10,25,50]" [defaultColDef]="defaultColDef" sideBar="filters" [autoGroupColumnDef]="{ headerName: 'Match Type'}"/>


    </p-tabPanel>
    <p-tabPanel header="Raw Metabolomics">
        <ag-grid-angular class="ag-theme-quartz" style="height: 400px;" [rowData]="mmdd.rawMetab"
            [columnDefs]="rawMetabColDef" [pagination]="true" [paginationPageSize]="10"
            [paginationPageSizeSelector]="[10,25,50]" [defaultColDef]="defaultColDef" sideBar="filters" />
    </p-tabPanel>
    <p-tabPanel header="GCF">
        <ag-grid-angular class="ag-theme-quartz" style="height: 400px;" [rowData]="mmdd.gcfs"
        [columnDefs]="gcfColDef" [pagination]="true" [paginationPageSize]="10"
        [paginationPageSizeSelector]="[10,25,50]" [defaultColDef]="defaultColDef" sideBar="filters" />

    </p-tabPanel>
</p-tabView>